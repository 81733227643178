import * as React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { navigate } from "gatsby";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import { Role } from "../modules/users/constants";
import { axiosMeduverse } from "../../axiosConfigs";
import { MantineSize } from "@mantine/core";
import { getConfig } from "../modules/configs/hooks";

function deleteCookie(name: string) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export class SigninProps {
    username: string = ''
    password: string = ''
    remember: boolean = false
    redirectUrl?: string = '/'
}

export type LayoutSizeProps = MantineSize | (string & {}) | number

export class AuthContextProps {
    userInfo: any | undefined
    signin: ((params: SigninProps) => void) | undefined
    signout: (() => void) | undefined;
    layoutSize: LayoutSizeProps = 'lg'
    setLayoutSize: (size: LayoutSizeProps) => void = (size: LayoutSizeProps) => { }
    defaultLanguage: string = ''
}

const AuthContext = createContext<AuthContextProps>(new AuthContextProps());

export function AuthProvider({ children }: any) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [userInfo, setUserInfo] = useState<any>();
    const [layoutSize, setLayoutSize] = useState<LayoutSizeProps>('lg');
    const [defaultLanguage, setDefaultLanguage] = useState('');

    const signin = (params: SigninProps) => {
        const { username, password, remember, redirectUrl } = params
        axiosMeduverse.post(`/auth/login`, { username, password }, {
            baseURL: process.env.GATSBY_API_URL,
        }).then((x) => {
            //console.log(x)
            if (!x) return;
            setCookie('user', x.data)
            if (redirectUrl) navigate(redirectUrl)
        })
    }
    const signout = () => {
        // console.log('deleteCookie')
        deleteCookie('user')
    };

    useEffect(() => {
        // console.log('cookies', cookies)
        if (cookies.user && cookies.user.accessToken) {
            const decoded = jwtDecode(cookies.user.accessToken);
            const diff = dayjs().diff(dayjs.unix(decoded.exp as number))
            if (diff > 0) { // expired
                //navigate('/signin')
            } else {
                setUserInfo(cookies.user)
            }
        } else {
            setUserInfo(undefined)
            navigate('/signin')
        }
    }, [cookies])

    useEffect(() => {
        if (!userInfo) return;

        getConfig('default_language').then(x => {
            if (x && x.data) {
                setDefaultLanguage(x.data.value)
            }
        })
    }, [userInfo])

    return (
        <>
            <AuthContext.Provider value={{ defaultLanguage, userInfo, signin, signout, layoutSize, setLayoutSize }}>
                {children}
            </AuthContext.Provider>
        </>
    );
};

export default AuthContext;

export function useAuthContext(): AuthContextProps {
    const context = useContext(AuthContext);
    return context;
}

export function useInRole(role: Role): boolean {
    const { userInfo } = useAuthContext();
    //console.log('userInfo', userInfo)
    const right = React.useMemo(() => {
        if (!userInfo) return false;
        return userInfo?.role === role;
    }, [userInfo])

    //console.log(right)
    return right;
}

export function useCheckRoles(roles: Array<Role>): boolean {
    const { userInfo } = useAuthContext();
    // console.log('userInfo', userInfo)
    const right = React.useMemo(() => {
        if (!userInfo) return false;

        if (!roles) return false;

        if (roles.length === 0) return true

        const { accessToken, role: userInfoRole } = userInfo
        const decoded = jwtDecode(accessToken);
        // console.log(decoded)
        let checked = false;

        // @ts-ignore
        const yourRoles = [...decoded?.roles, ...decoded?.realm_access?.roles, userInfoRole]

        yourRoles?.forEach((role: Role) => {
            if (roles.includes(role) === true) checked = true
        })

        return checked;
    }, [userInfo])

    //console.log(right)
    return right;
}
