export enum Role {
    None = 0,
    Player = 1,
    Tester = 2,
    Bot = 3,
    Admin = "Admin",
    EventCreator = 5,
    Editor = 6,
    BotOffline = 7,
    SalesMember = 8,
    SalesManager = "sales-manager",
}