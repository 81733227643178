import { Paper, Anchor, Image, Stack, Box, Button, SimpleGrid, Popover, ActionIcon, Group } from "@mantine/core";
import React from "react";
import { IconPhotoVideo, IconX } from '@tabler/icons-react';
export const MIME_IMAGE = ["image/png", "image/gif", "image/jpeg", "image/svg+xml", "image/webp", "image/avif"]
export const MIME_AUDIO = ["audio/mp3", "audio/ogg"];
export const MIME_VIDEO = ["video/mp4"];

export interface MediaPreviewProps { files: File[] }

export interface MediaDisplayProps {
    urls: string[]
    onDelete?: (file: string, group: 'images' | 'audios' | 'videos') => void | undefined
}

export function MediaPreview({ files, filterTypes = [...MIME_IMAGE, ...MIME_AUDIO, ...MIME_VIDEO] }: { files: File[], filterTypes: string[] }) {
    const cols = files?.filter(x => filterTypes.includes(x.type ?? '')).map((file, index) => {
        if (MIME_AUDIO.includes(file.type))
            return <Paper bg={'gray.2'} radius={'xl'} p={'md'}>{file.name}</Paper>

        const imageUrl = URL.createObjectURL(file);
        return <Image key={index} src={imageUrl} onLoad={() => URL.revokeObjectURL(imageUrl)} />;
    })
    return <SimpleGrid cols={1}>
        {cols}
    </SimpleGrid>
}
export function PicturePreviews({ files }: MediaPreviewProps) {
    const cols = files?.filter(x => MIME_IMAGE.includes(x.type ?? '')).map((file, index) => {
        const imageUrl = URL.createObjectURL(file);
        return <Image key={index} src={imageUrl} onLoad={() => URL.revokeObjectURL(imageUrl)} />;
    })
    return <SimpleGrid cols={3}>
        {cols}
    </SimpleGrid>
}
export function AudioPreviews({ files }: MediaPreviewProps) {
    const cols = files?.filter(x => MIME_AUDIO.includes(x.type ?? '')).map((file, index) => {
        return <Paper bg={'gray.2'} radius={'xl'} p={'md'} key={index}>{file.name}</Paper>
    })
    return <SimpleGrid cols={1}>
        {cols}
    </SimpleGrid>
}

export function VideoPreviews({ files }: MediaPreviewProps) {
    const cols = files?.filter(x => MIME_VIDEO.includes(x.type ?? '')).map((file, index) => {
        const imageUrl = URL.createObjectURL(file);
        return <Box key={index}>
            <Popover position="bottom" withArrow shadow="md" width={300}>
                <Popover.Target>
                    <Button radius={'xl'} w={'100%'} fullWidth><IconPhotoVideo /></Button>
                </Popover.Target>
                <Popover.Dropdown>
                    <Stack>
                        <video controls src={imageUrl} ></video>
                        <Anchor href={imageUrl} target="_blank" fz={'xs'} style={{ lineBreak: 'anywhere' }}>{imageUrl}</Anchor>
                    </Stack>
                </Popover.Dropdown>
            </Popover>
        </Box>
    })
    return <SimpleGrid cols={3}>
        {cols}
    </SimpleGrid>
}

export function PictureDisplays({ urls, onDelete }: MediaDisplayProps) {
    // console.log(urls)
    const cols = urls?.map((fileUrl, index) => {
        return <Stack key={fileUrl} pos={'relative'} w={'100%'} gap={'xs'}>
            <Image fit="contain" src={fileUrl} />
            {onDelete && <ActionIcon pos={'absolute'} top={5} right={5} radius={'xl'} size="compact-xs" color="red" onClick={() => onDelete && onDelete(fileUrl, 'images')}><IconX /></ActionIcon>}
        </Stack>
    });

    return <SimpleGrid cols={3}>
        {cols}
    </SimpleGrid>
}

export function AudioDisplays({ urls, onDelete }: MediaDisplayProps) {
    const cols = urls?.map((fileUrl, index) => {
        return <Stack key={fileUrl} pos={'relative'} w={'100%'} gap={'xs'}>
            <audio controls src={fileUrl} style={{ width: '100%' }}></audio>
            {/* <Anchor href={fileUrl} target="_blank" fz={'xs'} style={{ lineBreak: 'anywhere' }}>{fileUrl}</Anchor> */}

            {onDelete && <ActionIcon pos={'absolute'} top={-5} right={-5} radius={'xl'} size="compact-xs" color="red" onClick={() => onDelete && onDelete(fileUrl, 'audios')}><IconX /></ActionIcon>}
        </Stack>
    });

    return <SimpleGrid cols={1}>
        {cols}
    </SimpleGrid>
}

export function VideoDisplays({ urls, onDelete }: MediaDisplayProps) {
    const cols = urls?.map((fileUrl, index) => {
        return <Group key={fileUrl} w={'100%'} gap={'xs'}>
            <Box pos={'relative'}>
                <Popover position="bottom" withArrow shadow="md" width={300}>
                    <Popover.Target>
                        <ActionIcon variant="light" size={50} radius={'xl'}><IconPhotoVideo /></ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <Stack>
                            <video controls src={fileUrl} ></video>
                            <Anchor href={fileUrl} target="_blank" fz={'xs'} style={{ lineBreak: 'anywhere' }}>{fileUrl}</Anchor>
                        </Stack>
                    </Popover.Dropdown>
                </Popover>
                {onDelete && <ActionIcon pos={'absolute'} top={-5} right={-5} radius={'xl'} size="compact-xs" color="red" onClick={() => onDelete && onDelete(fileUrl, 'videos')}><IconX /></ActionIcon>}
            </Box>
        </Group>
    });

    return <SimpleGrid cols={3}>
        {cols}
    </SimpleGrid>
}