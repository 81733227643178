import { Box, Flex, Image } from "@mantine/core";
import { Link } from "gatsby";
import React from "react";

export default function Logo() {
    return <Box>
        <Link to="/" style={{ textDecorationLine: 'none' }}>
            <Flex align={'center'} gap={10}>
                <Image src={'/images/meduverse.png'} height={25} my={10} radius={'md'} />
                {/* <Title order={2} c={'dark'}>MEDUVERSE</Title> */}
            </Flex>
        </Link>
    </Box>
}