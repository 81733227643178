import { VI } from './config/languages'

const publicUrl = process.env.NEXT_PUBLIC_APEX_URL || ''

export const LS_KEY = 'meduverse_landing_language'

export const fetchLocale = async (locale: string) => {
  const response = await fetch(`${publicUrl}/locales/${locale}.json`)
  if (response.ok) {
    // eslint-disable-next-line no-return-await
    return await response.json()
  }

  console.error(`API: Failed to fetch locale ${locale}`, response.statusText)
  return null
}

export const getLanguageCodeFromLS = () => {
  try {
    const codeFromStorage = localStorage.getItem(LS_KEY)
    return codeFromStorage || VI.locale
  } catch {
    return VI.locale
  }
}
