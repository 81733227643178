import React from 'react';
import { Profile } from './Profile';
import Logo from './Logo';
import { ThemeSwitcher } from './ThemeSwitcher';
import { Box, Container, Group, Burger } from '@mantine/core';
import { LangSwitcher } from './LangSwitcher';
import { AppMenu } from './Menu';
import { useAuthContext } from '../context/AuthProvider';

export function MegaHeader({ opened, toggle }: any) {
    // const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const { layoutSize } = useAuthContext()

    return (
        <Box>
            <Container size={layoutSize}>
                <Group justify="space-between" h="100%">
                    {/* <Burger
                        opened={opened}
                        onClick={toggle}
                        hiddenFrom="sm"
                        size="sm"
                    /> */}
                    <Logo />
                    <AppMenu />
                    <Group>
                        {/* <LangSwitcher /> */}
                        <ThemeSwitcher />
                        <Profile />
                    </Group>
                    {/* <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" /> */}
                </Group>
            </Container>
        </Box>
    );
}