// src/theme.ts
import { Avatar, Button, Card, FileInput, Image, InputBase, MantineThemeOverride, Modal, Notification, Pagination, PasswordInput, SegmentedControl, Table, TableThead, Tabs, TagsInput, TextInput, createTheme } from '@mantine/core';
import { DatePickerInput, DateTimePicker } from '@mantine/dates';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const defaultProps = {
    radius: 'xl',
    //size: 'md',
    //fz: 'h6',
}

export const theme: MantineThemeOverride = createTheme({
    fontFamily: "Cabin",
    primaryColor: 'cyan',
    fontSizes: {
        // xs: '0.75rem',
        // sm: '1rem',
        // md: '1.2rem',
        // lg: '1.5rem',
        // xl: '1.8rem',
    },
    components: {
        InputBase: InputBase.extend({
            defaultProps
        }),
        TextInput: TextInput.extend({
            defaultProps
        }),
        FileInput: FileInput.extend({
            defaultProps
        }),
        Image: Image.extend({
            defaultProps: {
                fallbackSrc: '/images/noimage.webp'
            }
        }),
        PasswordInput: PasswordInput.extend({
            defaultProps
        }),
        Button: Button.extend({
            defaultProps: {
                ...defaultProps,
                fw: 500,
            }
        }),
        TimeInput: Button.extend({
            defaultProps
        }),
        DateTimePicker: DateTimePicker.extend({
            defaultProps: {
                ...defaultProps,
            }
        }),
        DatePickerInput: DatePickerInput.extend({
            defaultProps
        }),
        Modal: Modal.extend({
            defaultProps
        }),
        SegmentedControl: SegmentedControl.extend({
            defaultProps
        }),
        Notification: Notification.extend({
            styles: {
                body: {
                    lineBreak: 'anywhere'
                }
            }
        }),
        Tabs: Tabs.extend({
            defaultProps: {
                radius: 'md'
            }
        }),
        Pagination: Pagination.extend({
            defaultProps: {
                withEdges: true,
                radius: 'xl'
            }
        }),
        Table: Table.extend({
            defaultProps: {
                withColumnBorders: true
            },
        }),
        TableThead: TableThead.extend({
            defaultProps: {
                fz: 'md',
            }
        }),
        Card: Card.extend({
            defaultProps: {
                radius: 'lg',
                withBorder: true
            }
        }),
        TagsInput: TagsInput.extend({
            defaultProps: {
                ...defaultProps,
            },
        })
    }
});
