import React from 'react';
import { MegaHeader } from './MegaHeader';
import { StyleProp, MantineColor, AppShell, Container, useMantineColorScheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAuthContext } from '../context/AuthProvider';

interface LayoutProps {
    children: React.ReactNode;
    bg?: StyleProp<MantineColor>
}

export function Layout({ children }: LayoutProps) {
    const [opened, { toggle }] = useDisclosure();
    const { colorScheme } = useMantineColorScheme()
    const { layoutSize } = useAuthContext()

    return (
        <AppShell
            header={{
                height: 50,
                //collapsed: !pinned, 
                offset: true
            }}
            navbar={{
                width: 300,
                breakpoint: 'sm',
                collapsed: { mobile: !opened, desktop: true },
            }}
        >
            <AppShell.Header>
                <MegaHeader opened={opened} toggle={toggle} />
            </AppShell.Header>

            {/* <AppShell.Navbar p="md">Navbar</AppShell.Navbar> */}

            <AppShell.Main pb={15} w={'100%'} bg={colorScheme === 'dark' ? '' : '#f3f3f3'}>
                <Container size={layoutSize} pt={15}>
                    {children}
                </Container>
            </AppShell.Main>
        </AppShell>

    );
}

