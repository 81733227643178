import "nprogress/nprogress.css";
import "@mantine/core/styles.css";
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import React from 'react';
import { theme } from './src/theme';
import { AuthProvider } from './src/context/AuthProvider';
import { Layout } from './src/components';
import { Providers } from './src/components/Providers';

export const wrapPageElement = ({ element }: any) => {
    return <Providers theme={theme}>
        <AuthProvider>
            <Layout>{element}</Layout>
        </AuthProvider>
    </Providers>;
};