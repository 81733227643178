import React, { useState } from 'react';
import { useHotkeys } from '@mantine/hooks';
import { MantineColorScheme, MantineProvider, MantineThemeOverride } from '@mantine/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CookiesProvider } from 'react-cookie';
import { Notifications } from '@mantine/notifications';
import { LanguageProvider } from './localization';
import 'dayjs/locale/vi';

interface ProvidersProps {
    children: React.ReactNode;
    theme: MantineThemeOverride;
}

export function Providers({ children, theme }: ProvidersProps) {
    const [colorScheme, setColorScheme] = useState<MantineColorScheme>('dark');
    const toggleColorScheme = (value?: MantineColorScheme) =>
        setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

    useHotkeys([['mod+J', () => toggleColorScheme()]]);

    const queryClient = new QueryClient();

    return (<CookiesProvider defaultSetOptions={{ path: '/' }}>
        <MantineProvider theme={theme} withCssVariables>
            <QueryClientProvider client={queryClient}>
                <LanguageProvider>
                    <Notifications position='top-right' />
                    <>{children}</>
                </LanguageProvider>
            </QueryClientProvider>
        </MantineProvider>
    </CookiesProvider>
    );
}